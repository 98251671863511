
import {
    defineComponent, ref, reactive
} from 'vue';
import {
    list, ListHeaderItem, ListActionItem
} from '@/components/common/list';
import { device } from '@/data';
import { getListForInsDevice, deleteDevice } from '@/api/community/device';
import { overlayFormData } from '@/util/system';
import removeFunc from '@/methods/remove-func';
import SetControlDeviceDialog, { FormData } from '@/components/view/installer/community/build-content-model/components/set-control-device-dialog.vue';
import { updateSortField, getInitSortField, getSortImg } from '@/methods/sort-table';

// 搜索选项：mac、位置、账户、用户、品牌
const searchList = [{
    label: WordList.TabelPersonDeviceInHtmlMAC,
    name: 'MAC'
}, {
    label: WordList.TabelPersonDeviceInHtmlLocation,
    name: 'Location'
}, {
    label: WordList.MulListUpdateListTanleAccount,
    name: 'SipAccount'
}, {
    label: WordList.ProperAllTextResident,
    name: 'Owner'
}];
// 列表项
const column: ListHeaderItem[] = [{
    name: 'MAC',
    label: WordList.TabelPersonDeviceInHtmlMAC
}, {
    name: 'Location',
    label: WordList.TabelPersonDeviceInHtmlLocation,
    headerType: 'customize'
}, {
    name: 'Name',
    label: WordList.ProperAllTextResident
}, {
    name: 'RoomName',
    label: WordList.RDeviceSearchLabelRoomName
}, {
    name: 'UnitName',
    label: WordList.DeviceDetailDetailUnitName
}, {
    name: 'SipAccount',
    label: WordList.MulListUpdateListTanleAccount
}, {
    name: 'Status',
    label: WordList.MulListUpdateListTanleStatus,
    type: 'customize'
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime,
    headerType: 'customize'
}];
export default defineComponent({
    components: {
        list,
        SetControlDeviceDialog
    },
    setup() {
        // 控制表格刷新
        const updateData = ref(0);
        // 搜索条件
        const formData = reactive({
            searchKey: 'MAC',
            searchValue: '',
            Brand: 'all',
            ID: 'all',
            row: 10,
            page: 1,
            Sort: '',
            SortField: ''
        });
        overlayFormData(formData);
        // 表格数据获取
        const tableData = reactive<{row: object[];detail: object[];total: string}>({
            row: [],
            detail: [],
            total: '0'
        });

        // 获取第三方设备数据
        getThirdPartyDeviceApi();
        function getThirdPartyDeviceApi() {
            getListForInsDevice(formData, (res: {row: object[];detail: object[];total: string}) => {
                tableData.row = res.row;
                tableData.detail = res.detail;
                tableData.total = res.total;
                updateData.value += 1;
            });
        }

        // 弹窗数据
        const editData = reactive<FormData>({
            MAC: '',
            NetGroupNumber: '0',
            Location: '',
            ID: ''
        });
        // 控制弹窗是否显示的变量
        const isShowEditDeviceDialog = ref(false);
        // 编辑设备，显示弹窗
        function editDevice(item: FormData) {
            isShowEditDeviceDialog.value = true;
            editData.ID = item.ID;
            editData.Location = item.Location;
            editData.MAC = item.MAC;
            editData.NetGroupNumber = item.NetGroupNumber;
        }

        function deleteThirdPartyDevice(item: { ID: string}) {
            removeFunc(WordList.TabelDeleteText, () => {
                deleteDevice({ ID: item.ID }, getThirdPartyDeviceApi);
            });
        }

        // action操作 迁移备注：这部分不用迁移 主要是为了适配删除触发点击
        const action: ListActionItem[] = [
            {
                type: 'edit2',
                event(data) {
                    editDevice(data);
                },
                class: 'el-icon-my-modify modify-icon',
                title: WordList.TabelConfigInHtmlModify
            },
            {
                type: 'delete2',
                event(data: { ID: string }) {
                    deleteThirdPartyDevice(data);
                },
                class: 'el-icon-delete delete-icon',
                title: WordList.TabelConfigInHtmlDelete
            }
        ];

        // 获取初始排序
        const initSortRes = getInitSortField('communityThirdPartyDevice');
        formData.SortField = initSortRes.sortField;
        formData.Sort = initSortRes.sort;
        // 获取排序方式
        function getSortRes(table: string, sortField: string) {
            const { sort } = updateSortField(table, sortField);
            formData.SortField = sortField;
            formData.Sort = sort;
            getThirdPartyDeviceApi();
            updateData.value += 1;
        }

        return {
            searchList,
            column,
            device,
            editDevice,
            formData,
            isShowEditDeviceDialog,
            getThirdPartyDeviceApi,
            tableData,
            updateData,
            editData,
            deleteThirdPartyDevice,
            action,
            getSortRes,
            getSortImg
        };
    }
});
